import React from "react";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { Accordion, AccordionSummary, AccordionDetails, userStyles, getFileName } from "./PremiumListing.web";
// Customizable Area End

// Customizable Area Start
import PremiumListingSupplierController, {
    Props,
} from "./PremiumListingSupplierController";
import { Backdrop, Card, CardContent, Box, styled, Button, CardMedia, Chip, CircularProgress, Grid, Link, Popover, Tab, Tabs, Typography, FormControl, Select, MenuItem, InputAdornment, TextField } from "@material-ui/core";
import AppBarPage from "../../../components/src/AppBar.web";
import { logoImage, searchIcon, downArrow, pdflogo, csvLogo } from "./assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import Contacts from "../../../components/src/Contacts.web";
import Reviews from "../../../components/src/Reviews.web";
import ListingHeader from "../../../components/src/ListingHeader.web";
import ListingAbout from "../../../components/src/ListingAbout.web";
import CustomErrorToast from "../../../components/src/CustomErrorToast.web";
const CustomSelect = styled(Select)({
    backgroundColor: '#FAF5FF',
    borderRadius: '8px',
    border: "1px solid #CBD5E1",
    height: "50px",

    '& .MuiSelect-outlined': {
        borderRadius: '8px',
        background: "none"

    },
});

// Customizable Area End

// Customizable Area Start
export class PremiumListingSupplier extends PremiumListingSupplierController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderChips = (classes: any, chips: any) => {
        return (<Box className={classes.chipContainer}>
            {chips && chips.map((chip: any) => (
                <Box key={chip}>
                    <Chip className={classes.chipStyle} label={chip.name} />

                </Box>
            )

            )}

        </Box>)
    }
    renderCategories = (classes: any, data: any) => {
        return <div>
            {data && data.map((category: any, i: number) => (
                <Box className={classes.width550} key={i}>
                    <Typography className={`${classes.heading14} ${classes.marginTop20}`}>{category.category_name}</Typography>
                    <div>
                        {this.renderChips(classes, category.sub_categories)}
                    </div>
                </Box>
            ))}
        </div>
    }
    renderFunction = (classes: any) => {
        return (
            <TextField
                variant="outlined"
                placeholder="Search"
                fullWidth
                data-testid="searchBrands"
                value={this.state.searchBrands}
                onChange={this.handleSearchBrands}
                name="search"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon style={{ color: "#94A3B8" }} />
                        </InputAdornment>
                    ),
                }}
                className={classes.textFieldRoot}
            />



        )
    }
    renderFilter = (classes: any,anchor:any,filterFunction:any,handleClick:any,filterValue:string) => {
        return <Box className={`${classes.marginRight35}`}>
            <Box>
                <Button data-testId="recentFilter" onClick={handleClick}>
                    <Typography className={`${classes.recentText} ${classes.mr20} ${classes.reviewText3}`}>{filterValue=='featured'?"Featured First":filterValue}</Typography>
                    <ExpandMoreIcon/>
                </Button>
                <Popover
                    id={"test"}
                    data-testId="recentpopover"
                    open={anchor}
                    anchorEl={anchor}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Box style={{
                                                display: "flex",
                                                flexDirection: "column"
                                            }} >
                                                 <Button style={{
                                                    
                                                    textTransform: "none"
                                                }} onClick={(e) =>filterFunction("featured")}   >Featured First </Button>
                                                <Button style={{
                                                   
                                                    textTransform: "none"
                                                }} onClick={(e) =>filterFunction("recent")}   >Recent </Button>
                                                <Button style={{
                                                   
                                                    textTransform: "none"
                                                }} onClick={(e) =>filterFunction("ascending")} >Ascending </Button>
                                                <Button style={{
                                                    
                                                    textTransform: "none"
                                                }} onClick={(e) =>filterFunction("descending")} >Descending </Button>
                                            </Box>
                </Popover>

            </Box>
        </Box>
    }
    
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        const { isLoading,
            name,
            contactData,


        }: any = this.state

        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <AppBarPage
                    serachIcon={searchIcon}
                    logoImg={logoImage}
                    data-testId={"signInBtn"}
                    handleHeaderNavigation={() => this.navigateToAccount(this.state.userdetails?.user_type)}
                    downarrow={downArrow}
                    goToHomePage={() => this.handleNavigation("Home")}
                    handleClick={this.goToLoginPage}
                    username={this.state.userdetails}
                    dummyicon={""}
                    searchText={this.state.searchTermProp}
                    showDirectoryPopup={this.state.showDirectoryPopup}
                    searchDirectoryData={this.state.searchDirectoryData}
                    handleAdvancedSearch={this.handleAdvancedSearch}
                    handleSubmit={this.handleSubmit}
                    handleSearchInput={this.handleSearchInput}
                    handleUserSignout={this.handleUserSignout}
                    handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                />
                <Box className={classes.root}>
                    <Box className={`${classes.container}`}>
                        <Box className={classes.headerContainer}>
                            <ListingHeader userdetails={this.state.userdetails} handleBookmarkDelete={this.handleBookmarkDelete} domain_url={this.state.domain_url} bookmarked={this.state.bookmarked} data-testid="bookmark" handleBookmarkCall={this.handleBookmarkClick} showClaimed={false} showComapnyRating={true} showContact={false} showFeatured={this.state.featured} name={name} logo={this.state.logo} />
                            <Box className={classes.navBar}>
                                <Tabs
                                    value={this.state.tabValue}
                                    onChange={this.handleTabChange}
                                    centered
                                    classes={{ centered: classes.supplierTabContainer }}
                                    TabIndicatorProps={{ style: tabsIndicatorStyle }}
                                    data-testid="tabs"

                                >
                                    {tabsArray && tabsArray.map((item: any, index: any) => (
                                        <Tab key={index} value={index} label={item} style={{ color: this.colorDetect(index) }} />

                                    ))}

                                </Tabs>
                            </Box>
                        </Box>

                        <Box id="0-tab">
                            <ListingAbout expanded={this.state.aboutAccordion} data-testid="aboutAccordion" markets={this.state.markets} description={this.state.description} handleAccordionToggle={this.handleAccordionToggle} accordionState="aboutAccordion" />
                        </Box>

                        <Box id="1-tab" className={classes.accordionContainer}>
                            <Accordion data-testid="categoriesAccordion" onChange={() => this.handleAccordionToggle('categoriesAccordion')} expanded={this.state.categoriesAccordion}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.accordionHeading}>Categories</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.paddLeft56} >

                                    <Box className={classes.integrationContainer}>

                                        <Box className={classes.cardsContainer}>
                                            {this.renderCategories(classes, this.state.categories?.slice(0, 4))}
                                            {this.state.showMoreCategories ? this.renderCategories(classes, this.state.categories?.slice(4)) : ""}

                                        </Box>
                                        <Box className={classes.marginTop30}>
                                            {this.state.categories.length > 4 ? <Button data-testid="seemorebtn" onClick={() => this.setState({ showMoreCategories: !this.state.showMoreCategories })} variant="text" className={classes.categoryBtn}>
                                                {this.state.showMoreCategories ? <Typography variant="body1">See less</Typography> : <Typography variant="body1">See more</Typography>}
                                            </Button> : ""}

                                        </Box>


                                    </Box>

                                </AccordionDetails>
                            </Accordion>
                        </Box>

                        <Box id="2-tab" className={classes.accordionContainer}>

                            <Accordion data-testid="brandsAccordion" onChange={() => this.handleAccordionToggle('brandsAccordion')} expanded={this.state.brandsAccordion}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="Brands-content"
                                    id="Brands-header"
                                >
                                    <Typography className={classes.accordionHeading}>Brands</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.paddLeft56} style={{ paddingLeft: "56px", paddingRight: '56px' }}>
                                    <Box style={{ width: "100%", marginTop: '1rem' }} className={classes.integrationContainer}>
                                        <Box className={`${classes.inputContainer} ${classes.ratingHeader}  `}>
                                            <Grid container md={12}>
                                                <Grid item md={6} sm={12}>
                                                    {this.renderFunction(classes)}
                                                </Grid>
                                                <Grid item md={6} sm={12} xs={12} style={{
                                                    display: "flex", justifyContent:
                                                        "right", alignItems: "center"
                                                }}>
                                                    {this.renderFilter(classes,this.state.brands_anchorEl,this.handleBrandsFilterClick,this.handleBrandsClick,this.state.brands_order)}
                                                </Grid>
                                            </Grid>

                                        </Box>

                                        <Box className={`${classes.marginTop30} ${classes.cardsContainer}`}>
                                            {this.state.loadmoreBrand ? this.handlefilteredBrands()?.map((brand: any) => (
                                                <Link color="inherit" href={this.getListingUrl(brand.id, brand.payment_status)} underline="none"
                                                >

                                                    <Card key={brand.id} className={classes.cardItem}>
                                                        <CardMedia
                                                            component="img"
                                                            alt={`Brand-${brand.name}`}
                                                            height="180"
                                                            width="1"
                                                            image={brand.logo}
                                                            title="Restaurant-brands"
                                                            style={{ borderColor: "#D9D9D9" }}
                                                        />
                                                        <CardContent>
                                                            <Typography className={`${classes.cardTitle} ${classes.eventElipsis}`} style={{ textAlign: "center" }}>{brand.name}</Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Link>
                                            )) : this.handlefilteredBrands()?.slice(0, 10)?.map((brand: any) => (
                                                <Link color="inherit" href={this.getListingUrl(brand.id, brand.payment_status)} underline="none">
                                                    <Card key={brand.id} className={classes.cardItem}>

                                                        <CardMedia
                                                            component="img"
                                                            alt={`${brand.name}`}
                                                            height="180"
                                                            width="1"
                                                            image={brand.logo}
                                                            title="Restaurant"
                                                            style={{ borderColor: "#D9D9D9" }}
                                                        />


                                                        <CardContent style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                                            <Typography className={`${classes.cardTitle} ${classes.eventElipsis}`}>{brand.name}</Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Link>
                                            ))}

                                        </Box>

                                        <Box className={`${classes.marginTop10} ${classes.cardDescriptionContainer}`}>
                                            {this.handlefilteredBrands()?.length > 10 ?
                                                <Button data-testid="loadmoreBrands" onClick={this.handleBrandLoadmore} variant="text" className={classes.categoryBtn}>
                                                    <Typography variant="body1">Load more</Typography>
                                                </Button> :
                                                ""}

                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>


                        <Box id="3-tab" className={classes.accordionContainer}>

                            <Accordion data-testid="distributorsAccordion" onChange={() => this.handleAccordionToggle('distributorsAccordion')} expanded={this.state.distributorsAccordion}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="Distributors-content"
                                    id="Distributors-header"
                                >
                                    <Typography className={classes.accordionHeading}>Distributors</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.paddLeft56} style={{ paddingLeft: "56px", paddingRight: '56px' }}>
                                    <Box style={{ width: "100%" }} className={classes.integrationContainer}>
                                        <Box className={`${classes.inputContainer} ${classes.ratingHeader}`}>
                                            <Grid container md={12}>
                                                <Grid item md={6} sm={12}>
                                                    <Box style={{ width: "100%", marginTop: "1rem" }}>
                                                        <Box>
                                                            <TextField
                                                                variant="outlined"
                                                                placeholder="Search"
                                                                fullWidth
                                                                data-testid="searchDistributor"
                                                                value={this.state.searchDistributor}
                                                                onChange={this.handleSearchDistributor}
                                                                name="search"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <SearchIcon style={{ color: "#94A3B8" }} />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                className={classes.textFieldRoot}
                                                            />

                                                        </Box>

                                                    </Box>
                                                </Grid>
                                                <Grid item md={6} sm={12} xs={12} style={{
                                                    display: "flex",
                                                    justifyContent: "right",
                                                    alignItems: "center",

                                                }}>
                                                    {this.renderFilter(classes,this.state.distributor_anchorEl,this.handleDistributorFilterClick,this.handleDistributorClick,this.state.distributors_order)}
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box className={`${classes.marginTop30} ${classes.cardsContainer}`}>
                                            {this.state.loadmoreDistrbutor ? this.handlefilteredDistributor()?.map((distributor: any) => (
                                                <Link color="inherit" href={this.getListingUrl(distributor.id, distributor.payment_status)} underline="none">
                                                    <Card key={distributor.id} className={classes.cardItem}>

                                                        <CardMedia
                                                            component="img"
                                                            alt={`Distributor-${distributor.name}`}
                                                            height="180"
                                                            width="1"
                                                            image={distributor.logo}
                                                            title="Restaurant"
                                                            style={{ borderColor: "#D9D9D9" }}
                                                        />


                                                        <CardContent>
                                                            <Typography className={`${classes.cardTitle} ${classes.eventElipsis}`}>{distributor.name}</Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Link>
                                            )) : this.handlefilteredDistributor()?.slice(0, 10)?.map((distributor: any) => (
                                                <Link color="inherit" href={this.getListingUrl(distributor.id, distributor.payment_status)} underline="none">

                                                    <Card key={distributor.id} className={classes.cardItem}>

                                                        <CardMedia
                                                            component="img"
                                                            alt="Distributor_img"
                                                            height="180"
                                                            width="1"
                                                            image={distributor.logo}
                                                            title="Restaurant-distributor"
                                                            style={{ borderColor: "#D9D9D9" }}
                                                        />


                                                        <CardContent>
                                                            <Typography className={`${classes.cardTitle} ${classes.eventElipsis}`}>{distributor.name}</Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Link>
                                            ))}

                                        </Box>

                                        <Box className={`${classes.marginTop10} ${classes.cardDescriptionContainer}`}>

                                            {this.handlefilteredDistributor()?.length > 10 ? <Button data-testid="loadmoreDistributor" onClick={this.handleDistributorLoadmore} variant="text" className={classes.categoryBtn}>
                                                <Typography variant="body1">Load more</Typography>
                                            </Button> : ""}

                                        </Box>
                                    </Box>

                                </AccordionDetails>
                            </Accordion>
                        </Box>

                        <Box id="4-tab" className={classes.accordionContainer}>
                            <Accordion data-testid="filesAccordion" onChange={() => this.handleAccordionToggle('filesAccordion')} expanded={this.state.filesAccordion}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.accordionHeading}>Files</Typography>
                                </AccordionSummary>
                                <Box className={classes.integrationContainer} style={{ padding: "2rem", marginLeft: "1.4rem" }}>
                                    <Box className={classes.width550}>
                                        {this.state.supplier_files && this.state.supplier_files.map((item: any) => (
                                            <Grid container spacing={1} style={{ marginBottom: "1rem" }}>
                                                <Grid item md={4} sm={6} xs={6}>
                                                    <Box className={classes.fileItem} style={{ gap: "1rem" }}>
                                                        <img src={pdflogo} alt="filelogo" />
                                                        <Typography className={`${classes.neutralGray} ${classes.heading400}`}>
                                                            {getFileName(item.url)}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={4} sm={12} xs={12} style={{
                                                    display: "flex", justifyContent:
                                                        "left", alignItems: "center"
                                                }}>
                                                    <Box>
                                                        <a download href={item.url} className={`${classes.marginTop10} ${classes.downloadtxt}`}
                                                            style={{
                                                                fontSize: '18px',
                                                                fontWeight: 400,
                                                                lineHeight: '26px',
                                                            }}>
                                                            Download
                                                        </a>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Box>

                                    <Box className={classes.width550}>
                                        <Typography className={`${classes.heading500}`} style={{ paddingTop: "10px", marginBottom: '1rem' }}>CSV Catalogues</Typography>

                                        {this.state.product_file && this.state.product_file.map((item: any) => (
                                            <Grid container spacing={1} style={{ marginBottom: "1rem" }}>
                                                <Grid item md={4} sm={6} xs={6}>
                                                    <Box className={classes.fileItem}>
                                                        <img src={csvLogo} alt="filelogo" />
                                                        <Typography className={`${classes.ml10} ${classes.neutralGray} ${classes.heading400}`}>
                                                            {getFileName(item.url)}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={4} sm={12} xs={12} style={{
                                                    display: "flex", justifyContent:
                                                        "left", alignItems: "center"
                                                }}>
                                                    <Box>
                                                        <a download href={item.url} className={`${classes.marginTop10} ${classes.downloadtxt}`}>
                                                            Download
                                                        </a>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Box>



                                </Box>
                            </Accordion>
                        </Box>


                        <Reviews id="5-tab" hideRatingForm={this.state.hideRatingForm}
                            reviewComment={this.state.reviewComment}
                            handleReviewSubmit={this.handleReviewSubmit}
                            handleReviewComment={this.handleReviewComment}
                            handleRating={this.handleRating}
                            handleClose={this.handleClose}
                            handleEditBtn={this.handleEditBtn}
                            isEdit={this.state.editReview}
                            ratingVal={this.state.ratingVal}
                            data-testid="reviewAccordion" classes={classes}
                            reviewData={this.state.ratingsData}
                            handleAccordionToggle={this.handleAccordionToggle}
                            loadmore={this.state.loadmore}
                            userdetails={this.state.userdetails}
                            handleLoadMore={this.handleLoadMore}
                            handleReviewUpdate={this.handleReviewUpdate}
                            accordionState="reviewOpen" anchorEl={this.state.anchorEl}
                            openFilter={Boolean(this.state.anchorEl)}
                            handleClick={this.handleClick}
                            handleFilterClick={this.handleFilterClick}
                            filterValue={this.state.filterValue}
                            expanded={this.state.reviewAccordion}
                        />


                        <Contacts id={`6-tab`} expanded={this.state.contactAccordion} data-testid="contactAccordion" accordionState={"contactAccordion"} contactData={contactData} handleAccordionToggle={this.handleAccordionToggle} />


                    </Box>

                </Box>

                <CustomErrorToast data-testid="errorToast" open={this.state.reviewToast} onClose={this.handleReviewToast} />


            </>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
export const tabsArray = ["About", "Suppliers", "Brands", "Distributors", "Files", "Reviews", "Contact"]
export const tabsIndicatorStyle = { height: '3px', backgroundColor: "#B300ED" }
export default withStyles(userStyles)(PremiumListingSupplier)
// Customizable Area End
